import React, { useState, useRef } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { ContactType } from "../interfaces";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { UnControlled as CodeMirror } from "react-codemirror2";
import MaskedInput from "react-text-mask";

const contacts = [
  {
    name: "Chattanooga",
    address1: "821 Broad Street",
    address2: null,
    city: "Chattanooga",
    state: "TN",
    zip: "37402",
    phone: "4238771234",
    fax: "8887058513",
    webURL: "https://massingillagency.com/",
    type: ContactType.OfficeLocation
  },
  {
    name: "Acworth",
    address1: "3459 Acworth Due West Road",
    address2: "Ste 202",
    city: "Acworth",
    state: "GA",
    zip: "30101",
    phone: "7705290348",
    fax: "8887058513",
    webURL: "https://massingillagency.com/",
    type: ContactType.IndividualAgent
  }
];

const ContactConfigScreen = props => {
  const [state, setState] = useState({ values: undefined });

  const handleSubmit = values => {
    // console.log(values);
    values.contacts.forEach(value => {
      value.phone = value.phone.replace(/[()-\W]/gi, "");
      value.fax = value.fax.replace(/[()-\W]/gi, "");
    });
    setState({ ...state, values: values });
  };


  return (
    <>
      <Row>
        <Col>
          <h1>Configure Contact Info</h1>

        </Col>
      </Row>

      <Row>
        <Col>
          {contacts ? (
            <div>
              <Formik
                initialValues={null}
                //   onSubmit={values => setFields(values.fields)}
                onSubmit={handleSubmit}
                render={({ values }) => (
                  <Form>
                    <div>
                      <FieldArray
                        name="contacts"
                        render={arrayHelpers => (
                          <Row>
                            {values.contacts && values.contacts.length > 0 ? (
                              values.contacts.map((page, index) => (
                                <Col
                                  md={4}
                                  key={index}
                                  style={{ margin: "10px 0" }}
                                >
                                  {/* <div className="form-field-wrapper"> */}
                                  <Card>
                                    <Card.Header>Contact {index}</Card.Header>
                                    <Card.Body>
                                      <div className="form-group">
                                        <label>
                                          Name:{" "}
                                          <Field
                                            className="form-control"
                                            name={`contacts.${index}.name`}
                                          />
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          Address Line 1:
                                          <Field
                                            className="form-control"
                                            name={`contacts.${index}.address1`}
                                          />
                                        </label>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Address Line 2:
                                          <Field
                                            className="form-control"
                                            name={`contacts.${index}.address2`}
                                          />
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          City:
                                          <Field
                                            className="form-control"
                                            name={`contacts.${index}.city`}
                                          />
                                        </label>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          State:
                                          <Field
                                            className="form-control"
                                            name={`contacts.${index}.state`}
                                          />
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          Zip:
                                          <Field
                                            className="form-control"
                                            name={`contacts.${index}.zip`}
                                          />
                                        </label>
                                      </div>
                                      {/* <div className="form-group">
                                      <label>
                                        Phone:
                                        <Field
                                          className="form-control"
                                          name={`contacts.${index}.phone`}
                                        />
                                      </label>
                                    </div> */}
                                      <div className="form-group">
                                        <label>
                                          Phone:
                                          <Field
                                            className="form-control"
                                            name={`contacts.${index}.phone`}
                                            render={({ field }) => {
                                              return (
                                                <MaskedInput
                                                  mask={[
                                                    "(",
                                                    /[1-9]/,
                                                    /\d/,
                                                    /\d/,
                                                    ")",
                                                    " ",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    "-",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/
                                                  ]}
                                                  {...field}
                                                  id={`contacts.${index}.phone`}
                                                  className="form-control"
                                                />
                                              );
                                            }}
                                          />
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          Fax:
                                          <Field
                                            className="form-control"
                                            name={`contacts.${index}.fax`}
                                            render={({ field }) => {
                                              return (
                                                <MaskedInput
                                                  mask={[
                                                    "(",
                                                    /[1-9]/,
                                                    /\d/,
                                                    /\d/,
                                                    ")",
                                                    " ",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    "-",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/
                                                  ]}
                                                  {...field}
                                                  id={`contacts.${index}.fax`}
                                                  className="form-control"
                                                />
                                              );
                                            }}
                                          />
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          Web URL:
                                          <Field
                                            className="form-control"
                                            name={`contacts.${index}.webURL`}
                                          />
                                        </label>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Type:
                                          <Field
                                            component="select"
                                            className="form-control"
                                            name={`contacts.${index}.type`}
                                          >
                                            <option value="" />
                                            <option
                                              value={ContactType.OfficeLocation}
                                            >
                                              Office Location
                                            </option>
                                            <option
                                              value={
                                                ContactType.IndividualAgent
                                              }
                                            >
                                              Individual Agent
                                            </option>
                                          </Field>
                                        </label>
                                      </div>
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        } // remove a friend from the list
                                        variant={"danger"}
                                      >
                                        -
                                      </Button>
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.insert(index + 1, {
                                            name: "",
                                            address1: "",
                                            address2: "",
                                            city: "",
                                            state: "",
                                            zip: "",
                                            phone: "",
                                            fax: "",
                                            webURL: "",
                                            type: ""
                                          })
                                        } // insert an empty string at a position
                                        variant={"success"}
                                        style={{ margin: "0 10px" }}
                                      >
                                        +
                                      </Button>
                                      {/* </div> */}
                                    </Card.Body>
                                  </Card>
                                </Col>
                              ))
                            ) : (
                              <Button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    name: "",
                                    address1: "",
                                    address2: "",
                                    city: "",
                                    state: "",
                                    zip: "",
                                    phone: "",
                                    fax: "",
                                    webURL: "",
                                    type: ""
                                  })
                                }
                                variant={"primary"}
                                style={{ margin: "0 10px" }}
                              >
                                Add a Contact
                              </Button>
                            )}
                            <Col>
                              <Button type="submit">Apply Changes</Button>
                            </Col>
                          </Row>
                        )}
                      />
                    </div>
                  </Form>
                )}
              />
            </div>
          ) : (
            <h2>Please select a form to edit</h2>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <CodeMirror
            value={JSON.stringify(state.values, null, "\t")}
            options={{
              mode: "xml",
              theme: "material",
              lineNumbers: true
            }}
            onChange={(editor, data, value) => {
              // console.log(value)
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ContactConfigScreen;
