import { Stage, Layer, Rect, Text, Image } from "react-konva";
import Konva from "konva";
import React, { useRef, useState, useEffect } from "react";
import useImage from "use-image";
import Jimp from "jimp";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CustomImage from "./CustomImageComponent";

const AssetImgComponent = ({
  assetWidth,
  assetHeight,
  srcImgDataString,
  srcImgXPos,
  srcImgYPos,
  name
}) => {
  const [previewImgDataString, setPreviewImgDataString] = useState(null);
  const stageRef = useRef(null);
  const generatePreview = () => {
    const dataURL = stageRef.current.toDataURL();
    setPreviewImgDataString(dataURL);
  };

  return (
    <>
      {!previewImgDataString ? (
        <Col md={4}>
          <p>{name} is ready</p>
          <Button onClick={() => generatePreview()}>Generate Image</Button>
        </Col>
      ) : null}

      {previewImgDataString ? (
        <Col md={4}>
          <div>
            <h3>
              {name} {assetWidth}x{assetHeight}
            </h3>
            <a
              href={previewImgDataString}
              download={`${name} - ${assetWidth}x${assetHeight}`}
            >
              <img
                style={{ width: 200, border: "1px solid black" }}
                alt="preview"
                src={previewImgDataString}
              ></img>
            </a>
          </div>
        </Col>
      ) : null}

      {/* Stage, a.k.a. 'canvas'; remains hidden */}
      <div style={{ display: "none" }}>
        <Stage ref={stageRef} width={assetWidth} height={assetHeight}>
          {srcImgDataString ? (
            <Layer>
              <CustomImage
                x={srcImgXPos}
                y={srcImgYPos}
                src={srcImgDataString}
              />
            </Layer>
          ) : null}
          {}
        </Stage>
      </div>
    </>
  );
};

export default AssetImgComponent;
