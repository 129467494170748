import React, { useState } from "react";
import FormsScreen from "./Screens/FormsScreen";
import FileReaderComponent from "./Screens/FileReaderComponent";
import ContactConfigScreen from "./Screens/ContactConfigScreen";
import CompanyConfigScreen from "./Screens/CompanyConfigScreen";
import AppAssetCreatorScreen from "./Screens/AppAssetCreatorScreen";
import CarrierLogoComponent from "./Screens/CarrierLogoComponent";
import StockImageSearchComponent from "./Screens/StockImgSearchComponent";
import StockImageCaptureComponent from "./Screens/StockImageCaptureComponent";
import StockImageUploadComponent from "./Screens/StockImageUploadComponent";
import CarrierImagesGallery from "./Screens/CarrierImagesGallery"
import RichTextEditorComponent from "./Screens/RichTextEditorComponentV2"
import Amplify, { Auth } from "aws-amplify";
import amplify from "./aws-exports";

import { withAuthenticator, Authenticator } from "aws-amplify-react";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "react-bootstrap-typeahead/css/Typeahead.css";
import '@aws-amplify/ui/dist/style.css';

Amplify.configure(amplify);

const AppContainer = (children) => {
  return <div>{children}</div>;
};

const App: React.FC = () => {
  return (
        <Router>
          <Nav variant={"tabs"}>
            <Nav.Item>
              <Nav.Link>
                <Link to="/forms">Configure Forms</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to="/company">Configure Company</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to="/contact">Configure Contact Info</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to="/canvas">App Image Assets</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to="/carrier-logo">Format Carrier Logo</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to="/carrier-gallery">Carrier Gallery</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to="/stock-image-download">Stock Image Download</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to="/stock-image-upload">Stock Image Upload</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to="/stock-image-search">Stock Image Search</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to="/text-editor">App Text Editor</Link>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Container fluid style={{ marginTop: 20 }}>
            <Row>
              <Col md={4} style={{ borderRight: "1px solid #dee2e6" }}>
                <FileReaderComponent />
              </Col>
              <Col md={8}>
                <Switch>
                  <Route path="/forms">
                    <FormsScreen />
                  </Route>{" "}
                  <Route path="/company">
                    <CompanyConfigScreen />
                  </Route>
                  <Route path="/contact">
                    <ContactConfigScreen />
                  </Route>
                  <Route path="/canvas">
                    <AppAssetCreatorScreen />
                  </Route>
                  <Route path="/carrier-logo">
                    <CarrierLogoComponent />
                  </Route>
                  <Route path="/stock-image-download">
                    <StockImageCaptureComponent />
                  </Route>
                  <Route path="/stock-image-upload">
                    <StockImageUploadComponent />
                  </Route>
                  <Route path="/stock-image-search">
                    <StockImageSearchComponent />
                  </Route>
                  <Route path="/carrier-gallery">
                    <CarrierImagesGallery />
                  </Route>
                  <Route path="/text-editor">
                    <RichTextEditorComponent />
                  </Route>
                </Switch>
              </Col>
              <Col style={{display: "flex", justifyContent: "flex-end", position: "fixed", bottom: 0, padding: 10}}md={12}>
              <span>v1.11</span>
              </Col>
            </Row>
          </Container>
        </Router>
  );
};

export default withAuthenticator(App, true);
