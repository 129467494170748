import React, { FC, useState, useEffect } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import copy from "copy-to-clipboard";

type Props = {};

const CompanyConfigScreen: FC<Props> = () => {
  const [carriersData, setCarriers] = useState({
    allCarriers: null,
    selectedCarriers: [],
  });

  const getCarriers = () => {
    fetch("https://tier1-prod.codecolor.com/carriers/v2").then((res) =>
      res.json().then((json) => {
        json.carriers = json.carriers.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setCarriers({
          allCarriers: json.carriers,
          selectedCarriers: carriersData.selectedCarriers,
        });
      })
    );
  };

  const addSelectedCarrier = (carrierId) => {
    let carriers = carriersData.selectedCarriers;
    console.log(carrierId);
    if (carriers.includes(carrierId)) {
      carriers = carriers.filter(function (id) {
        return id !== carrierId;
      });
    } else {
      carriers.push(carrierId);
    }

    setCarriers({
      allCarriers: carriersData.allCarriers,
      selectedCarriers: carriers,
    });
  };

  const copyJSONValue = () => {
    copy(JSON.stringify(carriersData.selectedCarriers));
  };

  useEffect(() => {
    getCarriers();
  }, []);

  return (
    <div className="company-config-wrapper">
      <h1>Configure Company Settings</h1>
      {/* <span>[</span>
      {carriersData.selectedCarriers  && carriersData.selectedCarriers.length > 0 ?
       carriersData.selectedCarriers.map(carrierId => (<span>{carrierId},</span>)) : null}
       <span>]</span> */}
      <button onClick={() => copyJSONValue()}>Copy JSON Value</button>
      <CodeMirror
        value={JSON.stringify(carriersData.selectedCarriers)}
        options={{
          mode: "xml",
          theme: "material",
          lineNumbers: true,
        }}
        onChange={(editor, data, value) => {
        }}
      />
      {carriersData.allCarriers ? (
        <p>Showing {carriersData.allCarriers.length} Carriers</p>
      ) : null}
      <div className="carriers-container">
        {carriersData.allCarriers && carriersData.allCarriers.length > 0
          ? carriersData.allCarriers.map((carrier) => (
              <p key={carrier.id}>
                <label>
                  <input
                    onChange={(e) => addSelectedCarrier(carrier.id)}
                    type="checkbox"
                  ></input>
                  {carrier.name}

                </label>
              </p>
            ))
          : null}
      </div>
    </div>
  );
};

export default CompanyConfigScreen;
