import React, { Component } from "react";
import { FormInt, Page, FormField } from "../interfaces";
import PagesComponent from "./PagesComponent";
import FormsComponent from "./FormsComponent";
import FieldsComponent from "./FieldsComponent";

import { formConfig } from "../forms";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "../App.css";
import copy from "copy-to-clipboard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");

type FormsScreenProps = {};

class FormsScreen extends Component<{}, {}> {
  constructor(props: FormsScreenProps) {
    super(props);

    this.setPages = this.setPages.bind(this);
    this.setForms = this.setForms.bind(this);
    this.setFields = this.setFields.bind(this);
    this.setActiveForms = this.setActiveForms.bind(this);
    this.setActiveFields = this.setActiveFields.bind(this);
  }

  state = {
    pages: formConfig.pages as Page[],
    activeForms: undefined,
    activeFormsIndex: null,
    activeFormsTitle: "",
    activeFields: undefined,
    activeFieldsIndex: null,
    activeFieldsTitle: "",
    JSONvalue: "",
  };

  setPages(pages: Page[]) {
    this.setState({ pages: pages });
  }

  setForms(forms: FormInt[]) {
    const newPages = this.state.pages.slice(0);
    newPages[this.state.activeFormsIndex].forms = forms;
    this.setState({ activeForms: forms });
    this.setState({ pages: newPages });
  }

  setFields(fields: FormField[]) {
    const newPages = this.state.pages.slice(0);
    const newForms = newPages[this.state.activeFormsIndex].forms;
    newForms[this.state.activeFieldsIndex].fields = fields;
    this.setState({ activeFields: fields });
    this.setState({ pages: newPages });
  }

  setActiveForms(index: number) {
    const activeForms = this.state.pages[index].forms;
    this.setState({ activeFormsIndex: index });
    this.setState({ activeForms: activeForms });
    this.setState({ activeFormsTitle: this.state.pages[index].title });
  }

  setActiveFields(index: number) {
    const activeFields = this.state.activeForms[index].fields;
    this.setState({ activeFieldsIndex: index });
    this.setState({ activeFields: activeFields });
    this.setState({ activeFieldsTitle: this.state.activeForms[index].title });
  }

  copyJSONValue() {
    copy(JSON.stringify(this.state.pages, null, "\t"));
  }

  render() {
    return (
      <>
        <h1>Configure Forms</h1>
        <Row>
          <Col>
            <PagesComponent
              setPages={this.setPages}
              pages={this.state.pages}
              setActiveForms={this.setActiveForms}
            />
          </Col>{" "}
          <Col>
            <FormsComponent
              key={"form" + this.state.activeFormsIndex}
              setForms={this.setForms}
              forms={this.state.activeForms}
              setActiveFields={this.setActiveFields}
              title={this.state.activeFormsTitle}
            />
          </Col>{" "}
        </Row>
        <Row style={{marginTop: 15}} className="form-fields-row">
            <FieldsComponent
              key={"field" + this.state.activeFieldsIndex}
              setFields={this.setFields}
              fields={this.state.activeFields}
              title={this.state.activeFieldsTitle}
            />
        </Row>

        <Row>
          <CodeMirror
            value={JSON.stringify(this.state.pages, null, "\t")}
            options={{
              mode: "xml",
              theme: "material",
              lineNumbers: true,
            }}
            onChange={(editor, data, value) => {
              // console.log(value)
            }}
          />
        </Row>
        <Row>
          <Button
            onClick={(e) => {
              this.copyJSONValue();
            }}
          >
            Copy JSON Value
          </Button>
        </Row>
      </>
    );
  }
}
export default FormsScreen;
