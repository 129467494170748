import { Stage, Layer, Rect, Text, Image } from "react-konva";
import Konva from "konva";
import React, { useRef, useState, useEffect } from "react";
import useImage from "use-image";
import Jimp from "jimp";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CustomImage from "./CustomImageComponent";
import AssetImgComponent from "./AssetImgComponent";
import { calcImgXPos, calcImgYPos } from "../helpers";

const assetPresets = [
  { name: "App Icon", width: 192, height: 192 },
  { name: "Splash Screen", width: 1242, height: 2436 },
  { name: "App Store App Logo", width: 1024, height: 1024 },
  { name: "App Store Product Page Artwork", width: 4320, height: 1080 },
  { name: "Play Store App Logo", width: 512, height: 512 },
  { name: "Play Store Feature Graphic Screen", width: 1024, height: 500 }
];

const CanvasComponent = () => {
  const [imgConfigs, setImgConfigs] = useState(null);
  const [inputKey, setInputKey] = useState(Math.ceil(Math.random() * 10000000));

  const clearState = () => {
    setImgConfigs(null);
    setInputKey(Math.ceil(Math.random() * 10000000));
  };

  const fileInput = useRef(null);

  const jimpTheImage = (file, assetWidth, assetHeight, name) => {
    let imgWidth;
    let imgHeight;

    if (assetHeight >= assetWidth) {
      imgWidth = assetWidth * 0.75;
    } else {
      imgHeight = assetHeight * 0.75;
    }

    return new Promise((resolve, reject) => {
      Jimp.read(file)
        .then(img => {
          img
            .resize(imgWidth || Jimp.AUTO, imgHeight || Jimp.AUTO)
            .getBase64(Jimp.MIME_PNG, (err, string) => {
              if (err) {
                console.log(err);
              }
              const imgXPos = calcImgXPos(assetWidth, img.bitmap.width);
              const imgYPos = calcImgYPos(assetHeight, img.bitmap.height);
              resolve({
                imgXPos: imgXPos,
                imgYPos: imgYPos,
                base64String: string,
                assetWidth: assetWidth,
                assetHeight: assetHeight,
                name: name
              });
            });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };

  const processImageV2 = file => {
    Promise.all([
      ...assetPresets.map(preset =>
        jimpTheImage(file, preset.width, preset.height, preset.name)
      )
    ]).then(imgConfigs => {
      setImgConfigs(imgConfigs);
    });
  };

  const handleFileInput = event => {
    event.preventDefault();
    const f = fileInput.current.files[0];
    const reader: FileReader = new FileReader();

    reader.onload = async (e: Event) => {
      const file = reader.result as string;

      processImageV2(file);
    };

    reader.readAsArrayBuffer(f);
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Create Image Assets</h1>
        </Col>
      </Row>
      <Row>
        {imgConfigs
          ? imgConfigs.map(
              ({
                imgXPos,
                imgYPos,
                base64String,
                assetWidth,
                assetHeight,
                name
              }) => (
                <AssetImgComponent
                  name={name}
                  assetWidth={assetWidth}
                  assetHeight={assetHeight}
                  srcImgDataString={base64String}
                  srcImgXPos={imgXPos}
                  srcImgYPos={imgYPos}
                />
              )
            )
          : null}
      </Row>

      <Row>
        <Col>
          <input
            key={inputKey}
            ref={fileInput}
            type="file"
            name="file"
            id="file"
            onChange={handleFileInput}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={() => clearState()}>Clear</Button>
        </Col>
      </Row>
    </>
  );
};

export default CanvasComponent;
