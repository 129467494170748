import React, { useState, useEffect } from "react";
import axios from "axios";

interface CanvasRef{
    current: any;
}

const Gallery = () => {
  let imgRef = React.createRef();
  let canvasRef: CanvasRef = React.createRef();
  let logRef = () => {
    console.log(imgRef);
    const ctx = canvasRef.current.getContext("2d");
    ctx.drawImage(imgRef.current, 0, 0);
    console.log(canvasRef.current.toDataURL());
  };
  const [data, setData] = useState({ carriers: [] });

  useEffect(() => {
    const fetchData = () => {

      axios("https://tier1-prod.codecolor.com/carriers/v2").then(result => {
        console.log(result.data);
        result.data.carriers.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setData(result.data);
      });
    };
    fetchData();
  }, []);
  return (
    <main>
      {/* <canvas ref={canvasRef} height={250} width={500}></canvas> */}
      {/* <img crossOrigin="anonymous" onClick={()=> logRef()} ref={imgRef} src={"https://s3.amazonaws.com/cdn.codecolor.com/img/carrier-logos/asi.png"} alt={"test"}/> */}
      {data.carriers.map(carrier => (
        <div key={carrier.id} className="logo-container">
          {carrier.logoURI ? (
            <img
              style={carrierLogoStyles}
              className="carrier-logo"
              src={carrier.logoURI}
              alt={`${carrier.name}-logo`}
            />
          ) : (
            <p style={missingDataStyles}>No Logo!</p>
          )}
          <p style={{ fontWeight: "bold" }}>
            {carrier.id}: {carrier.name}
          </p>
          <p>
            {carrier.loginURL ? (
              <a href={carrier.loginURL}>URL</a>
            ) : (
              <span style={missingDataStyles}>"No URL"</span>
            )}
          </p>
          <p>
            Payments:{" "}
            {carrier.paymentsPhoneNumber || (
              <span style={missingDataStyles}>"No Payments Number"</span>
            )}
          </p>
          <p>
            Claims:{" "}
            {carrier.claimsPhoneNumber || (
              <span style={missingDataStyles}>"No Claims Number"</span>
            )}
          </p>
        </div>
      ))}
    </main>
  );
}
export default Gallery;

const carrierLogoStyles = {
  height: "auto",
  maxWidth: "100%",
  border: "1px solid black"

} as React.CSSProperties;

const missingDataStyles = {
  color: "red",
  fontWeight: "bold"
} as React.CSSProperties;;
