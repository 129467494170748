import React, { useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { baseUrl } from "../config";

interface HTMLImg {
  src: any;
  naturalHeight: number;
  naturalWidth: number;
}

const StockImageUploadComponent = () => {
  const [inputKey, setInputKey] = useState(Math.ceil(Math.random() * 10000000));
  const [isUploading, setIsUploading] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [srcString, setSrcString] = useState(null);
  const [imgFileObjects, setImgFileObjects] = useState([]);
  const [uploadResponses, setUploadResponses] = useState([]);

  const clearState = () => {
    setShowSuccessBanner(false);
    setShowErrorBanner(false);
    setInputKey(Math.ceil(Math.random() * 10000000));
  };

  const fileInput = useRef(null);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileInput = async (event) => {
    setSrcString(await toBase64(fileInput.current.files[0]));
    onFileInputChange();
    event.preventDefault();
  };

  const onFileInputChange = () => {
    let promiseArray = [];

    fileInput.current.files.forEach((file) => {
      promiseArray.push(
        new Promise(async (resolve, reject) => {
          const str64 = await toBase64(file);
          var imgEl = new Image() as HTMLImg;
          imgEl.src = str64;

          // this is absurd; but it works
          setTimeout(() => {
            const imgHeight = imgEl.naturalHeight;
            const imgWidth = imgEl.naturalWidth;
            console.log(imgHeight);
            console.log(imgWidth);
            const imgFileObject = {
              file: file,
              height: imgHeight,
              width: imgWidth,
              base64String: str64,
            };
            resolve(imgFileObject);
          }, 500);
        })
      );
    });
    Promise.all(promiseArray).then((val) => {
      setImgFileObjects(val);
      console.log(val);
    });
  };

  const uploadImage = async () => {
    setIsUploading(true);
    setShowSuccessBanner(false);
    const fetchPromises = [];

    imgFileObjects.forEach((obj) => {
      let fileData = obj.file;
      let filename = obj.file.name;
      let imgHeight = obj.height;
      let imgWidth = obj.width;

      let data = new FormData();
      data.append("attachment", fileData, filename);
      data.append("height", imgHeight);
      data.append("width", imgWidth);

      const options = {
        method: "POST",
        body: data,
      };
      fetchPromises.push(fetch(`${baseUrl}/upload`, options));
    });

    await Promise.all(fetchPromises)
      .then(async (responses) => {
        const errs = responses.filter((response) => !response.ok);
        errs.length > 0 ? setShowErrorBanner(true) : setShowSuccessBanner(true);
        setIsUploading(false);
        setImgFileObjects([]);
      })
      .catch((err) => {
        console.log(err);
        setIsUploading(false);
        setShowErrorBanner(true);
      });
  };

  return (
    <Row>
      <Col md={12}>
        <h1>Upload Stock Images</h1>
      </Col>
      {srcString ? (
        <Col md={12}>
          <Row>
            {imgFileObjects.map((obj) => (
              <Col md={3} key={obj.file.name}>
                <img height={200} src={obj.base64String} alt={"preview"}></img>
              </Col>
            ))}
          </Row>
        </Col>
      ) : null}
      {showErrorBanner ? (
        <Col md={12}>
          <Alert variant={"danger"}>
            There was a problem with one or more of your uploads!
          </Alert>
        </Col>
      ) : null}
      {showSuccessBanner ? (
        <Col md={12}>
          <Alert variant={"success"}>Images uploaded successfully!</Alert>
        </Col>
      ) : null}
      {!isUploading ? (
        <>
          <Col md={12}>
            <input
              ref={fileInput}
              key={inputKey}
              type="file"
              name="file"
              id="file"
              onChange={handleFileInput}
              multiple
            />
          </Col>

          <Col md={12}>
            <Button onClick={() => uploadImage()}>Upload</Button>
            <Button onClick={() => clearState()}>Clear</Button>
          </Col>
        </>
      ) : (
        <Col>
          {" "}
          <p>Uploading your images</p>
          <Spinner animation="border" variant="primary" />
        </Col>
      )}
    </Row>
  );
};

export default StockImageUploadComponent;
