import { FormConfig, FormType, IconSet } from "./interfaces";
const stateOptions = [
  { label: "AK", value: "AK" },
  { label: "AL", value: "AL" },
  { label: "AR", value: "AR" },
  { label: "AZ", value: "AZ" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DC", value: "DC" },
  { label: "DE", value: "DE" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "HI", value: "HI" },
  { label: "IA", value: "IA" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "MA", value: "MA" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MO", value: "MO" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "NC", value: "NC" },
  { label: "ND", value: "ND" },
  { label: "NE", value: "NE" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NV", value: "NV" },
  { label: "NY", value: "NY" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VA", value: "VA" },
  { label: "VT", value: "VT" },
  { label: "WA", value: "WA" },
  { label: "WI", value: "WI" },
  { label: "WV", value: "WV" },
  { label: "WY", value: "WY" }
];
export const formConfig: FormConfig = {
  pages: [
    {
      title: "Update My Policy",
      forms: [
        {
          title: "Add/Update Driver",
          enableAudioRecordings: false,
          type: FormType.Contact,
          iconSet: IconSet.FontAwesome,
          iconName: "user",
          recipEmail: "mark@massingillagency.com",
          fields: [
            {
              title: "My Name",
              type: "text",
              name: "myName",
              required: true 
            },
            {
              title: "Insured Name",
              type: "text",
              name: "insuredName"
            },
            {
              title: "New Driver's Name",
              type: "text",
              name: "newDriversName"
            },
            { title: "Policy Number", type: "text", name: "policyNumber" },

            { title: "My Phone Number", type: "phone", name: "primaryPhone", required: true  },
            {
              title: "Driver's License Photo",
              type: "single-img",
              name: "driversLicensePhoto"
            },
            {
              title: "Notice",
              type: "info",
              name: "disclaimer",
              value:
                "Coverage is not bound and alterations are not made in response to requests submitted through this application until you receive written confirmation from an agent."
            },
            { title: "Your Signature", type: "signature", name: "signature" }
          ]
        },
        {
          title: "Add/Change Vehicle",
          enableAudioRecordings: false,
          type: FormType.Contact,
          iconSet: IconSet.MaterialCommunityIcons,
          iconName: "car",
          recipEmail: "mark@massingillagency.com",
          fields: [
            {
              title: "My Name",
              type: "text",
              name: "myName",
              required: true
            },

            {
              title: "Insured Name",
              type: "text",
              name: "insuredName",
              required: true
            },
            {
              title: "Vehicle VIN #",
              type: "text",
              name: "vehicleVINNumber"
            },
            {
              title: "Vehicle Model Year",
              type: "text",
              name: "vehicleModelYear"
            },
            {
              title: "Vehicle Make",
              type: "text",
              name: "vehicleMake"
            },
            {
              title: "Vehicle Model",
              type: "text",
              name: "vehicleModel"
            },

            { title: "Policy Number", type: "text", name: "policyNumber" },

            { title: "My Phone Number", type: "phone", name: "primaryPhone", required: true },
            {
              title: "Address Line 1",
              type: "text",
              name: "addressLine1"
            },
            {
              title: "Address Line 2",
              type: "text",
              name: "addressLine2"
            },
            {
              title: "City",
              type: "text",
              name: "city"
            },
            {
              title: "State",
              type: "picker",
              name: "state",
              options: stateOptions
            },
            {
              title: "Zip",
              type: "number",
              name: "zip"
            },
            {
              title: "Comments",
              type: "textArea",
              name: "comments"
            },
            {
              title: "Notice",
              type: "info",
              name: "disclaimer",
              value:
                "Coverage is not bound and alterations are not made in response to requests submitted through this application until you receive written confirmation from an agent."
            },
            { title: "Your Signature", type: "signature", name: "signature" }
          ]
        },
        {
          title: "Other Policy Change",
          enableAudioRecordings: false,
          type: FormType.Contact,
          iconSet: IconSet.MaterialCommunityIcons,
          iconName: "square-edit-outline",
          recipEmail: "mark@massingillagency.com",
          fields: [
            {
              title: "My Name",
              type: "text",
              name: "myName",
              required: true
            },

            {
              title: "Insured Name",
              type: "text",
              name: "insuredName",
              required: true
            },
            { title: "Policy Number", type: "text", name: "policyNumber" },

            { title: "My Phone Number", type: "phone", name: "primaryPhone", required: true  },
            {
              title: "Address Line 1",
              type: "text",
              name: "addressLine1"
            },
            {
              title: "Address Line 2",
              type: "text",
              name: "addressLine2"
            },
            {
              title: "City",
              type: "text",
              name: "city"
            },
            {
              title: "State",
              type: "picker",
              name: "state",
              options: stateOptions
            },
            {
              title: "Zip",
              type: "number",
              name: "zip"
            },
            {
              title: "Describe the Changes You Want to Make",
              type: "textArea",
              name: "comments"
            },
            {
              title: "Photos",
              type: "multi-img",
              name: "photos"
            },
            {
              title: "Notice",
              type: "info",
              name: "disclaimer",
              value:
                "Coverage is not bound and alterations are not made in response to requests submitted through this application until you receive written confirmation from an agent."
            },
            { title: "Your Signature", type: "signature", name: "signature" }
          ]
        }
      ]
    },
    {
      title: "File a Claim",
      forms: [
        {
          title: "Auto Claim",
          enableAudioRecordings: true,
          type: FormType.Contact,
          iconSet: IconSet.MaterialCommunityIcons,
          iconName: "car",
          recipEmail: "mark@massingillagency.com",
          fields: [
            {
              title: "My Name",
              type: "text",
              name: "myName",
              required: true 
            },
            { title: "Insured Name", type: "text", name: "insuredName" },

            { title: "Policy Number", type: "text", name: "policyNumber" },

            {
              title: "My Phone Number",
              type: "phone",
              name: "primaryPhone",
              required: true 
            },
            {
              title: "Location of Accident",
              type: "location",
              name: "locationOfAccident"
            },
            {
              title: "Date of Accident",
              type: "date",
              name: "dateOfAccident"
            },
            {
              title: "Time of Accident",
              type: "time",
              name: "timeOfAccident"
            },

            {
              title: "Photos",
              type: "multi-img",
              name: "photos"
            }
          ]
        },
        {
          title: "Home Claim",
          enableAudioRecordings: true,
          type: FormType.Contact,
          iconSet: IconSet.FontAwesome,
          iconName: "home",
          recipEmail: "mark@massingillagency.com",
          fields: [
            {
              title: "My Name",
              type: "text",
              name: "myName",
              required: true 
            },
            { title: "Policy Number", type: "text", name: "policyNumber" },

            { title: "My Phone Number", type: "phone", name: "primaryPhone", required: true  },

            {
              title: "Photos",
              type: "multi-img",
              name: "photos"
            },
            { title: "What Happened?", type: "textArea", name: "whatHappened" }
          ]
        }
      ]
    }
  ]
};
