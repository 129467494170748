import React, { useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {baseUrl} from "../config"

const StockImageCaptureComponent = () => {
  const urlInputRef = useRef(null);
  const filenameInputRef = useRef(null);
  const [isGetting, setIsGetting] = useState(false);


  const getZip = (userFilename, url) => {
    setIsGetting(true);
    fetch(`${baseUrl}/download`, 


    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: url,
        filename: userFilename,
      }),
    })
      //https://stackoverflow.com/questions/3749231/download-file-using-javascript-jquery
      .then((resp) => {
        setIsGetting(false);
        console.log(resp);

        var filename = userFilename;
        resp.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
      })

      .catch((err) => {
        console.log(err);
        setIsGetting(false);
      });
  };
  return (
    <Row>
      
      <Col md={12}>
        <h1>Download Stock Images</h1>
      </Col>
      {!isGetting ? (
        <Col>
          <label>
            File Name
            <input
              className="form-control"
              type="text"
              ref={filenameInputRef}
            />
          </label>
          <br />
          <label>
            URL
            <input className="form-control" type="text" ref={urlInputRef} />
          </label>
          <br />
          <Button
            onClick={() =>
              getZip(filenameInputRef.current.value, urlInputRef.current.value)
            }
          >
            Download
          </Button>
          {/* <Button onClick={() => console.log(filenameInputRef.current.value)}>fname</Button> */}
        </Col>
      ) : (
        <Col>
          <p>Getting your images</p>

          <Spinner animation="border" variant="primary" />
        </Col>
      )}
      <Col md={12} style={{ marginTop: 15 }}>
        <h2>Facts</h2>
        <p>
          The server will respond with a zip archive containing four images. Their height dimesions are: </p>
        <ul>
          <li>Large: 1920px</li>
          <li>Medium: 1080px</li>
          <li>Small: 720px</li>
          <li>Original: Original image height</li>
        </ul>
      </Col>
    </Row>
  );
};

export default StockImageCaptureComponent;
