import { Stage, Layer, Rect, Text, Image } from "react-konva";
import Konva from "konva";
import React, { useRef, useState, useEffect } from "react";
import useImage from "use-image";
import Jimp from "jimp";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CustomImage from "./CustomImageComponent";
import AssetImgComponent from "./AssetImgComponent";
import { calcImgXPos, calcImgYPos } from "../helpers";

/**
 * 
 * 
 * handle the case when img is wider than tall but still too tall
 * i.e. check height; if it's greater than 300, shrink it to .75 of height
 * 
 */

interface ImgObj {
  base64String: String;
}

const CarrierLogoComponent = () => {
  const fileInput = useRef(null);
  const [imgConfigObj, setImgConfigObj] = useState(null);
  const [inputKey, setInputKey] = useState(Math.ceil(Math.random() * 10000000));

  const processImage = file => {
    jimpTheImage(file).then((imgObj: ImgObj) => {
      setImgConfigObj(imgObj);
    });
  };

  const clearState = () => {
    setImgConfigObj(null);
    setInputKey(Math.ceil(Math.random() * 10000000));
  };

  const handleFileInput = event => {
    event.preventDefault();
    const f = fileInput.current.files[0];
    const reader: FileReader = new FileReader();

    reader.onload = async (e: Event) => {
      const file = reader.result as string;

      processImage(file);
    };

    reader.readAsArrayBuffer(f);
  };

  const jimpTheImage = async file => {
    let assetHeight = 300;
    let assetWidth = 600;
    let imgWidth;
    let imgHeight;

    let imgHeightBeforeResize;
    let imgWidthBeforeResize;

    await Jimp.read(file).then(img => {
      imgHeightBeforeResize = img.bitmap.height;
      imgWidthBeforeResize = img.bitmap.width;
    });

    console.log(imgWidthBeforeResize);
    console.log(imgHeightBeforeResize);

    if (imgWidthBeforeResize > imgHeightBeforeResize) {
      imgWidth = assetWidth * 0.9;
    } else {
      imgHeight = assetHeight * 0.75;
    }

    return new Promise((resolve, reject) => {
      Jimp.read(file)
        .then(img => {
          img
            .autocrop()
            .resize(imgWidth || Jimp.AUTO, imgHeight || Jimp.AUTO)
            .getBase64(Jimp.MIME_PNG, (err, string) => {
              if (err) {
                console.log(err);
              }
              const imgXPos = calcImgXPos(assetWidth, img.bitmap.width);
              const imgYPos = calcImgYPos(assetHeight, img.bitmap.height);
              resolve({
                base64String: string,
                imgXPos: imgXPos,
                imgYPos: imgYPos
              });
            });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Format a Carrier Logo</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            key={inputKey}
            ref={fileInput}
            type="file"
            name="file"
            id="file"
            onChange={handleFileInput}
          />
        </Col>
      </Row>
      <Row>
        {imgConfigObj ? (
          <Col>
            <AssetImgComponent
              assetWidth={600}
              assetHeight={300}
              srcImgDataString={imgConfigObj.base64String}
              srcImgXPos={imgConfigObj.imgXPos}
              srcImgYPos={imgConfigObj.imgYPos}
              name={"Carrier Logo"}
            />
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col>
          <Button onClick={() => clearState()}>Clear</Button>
        </Col>
      </Row>
    </>
  );
};

export default CarrierLogoComponent;
