export interface FormConfig {
  pages: Page[];
}

export interface Page {
  title: string;
  forms: FormInt[];
}

export interface FormInt {
  title: string;
  fields: FormField[];
  enableAudioRecordings: boolean;
  type: FormType;
  iconSet: IconSet;
  iconName: string;
  recipEmail: string;
}

export interface FormField {
  name: string;
  title: string;
  type: string;
  required?: boolean;
  value?: string;
  options?: object[];
}

export enum ContactType {
  OfficeLocation = "officeLocation",
  IndividualAgent = "individualAgent",
}

export enum IconSet {
  IonIcons = "ionIcons",
  MaterialCommunityIcons = "materialCommunityIcons",
  MaterialIcons = "materialIcons",
  FontAwesome = "fontAwesome",
  Feather = "feather",
}

export enum FormType {
  Contact = "contact",
  Profile = "profile",
}

export enum FormFieldType {
  Text = "text",
  Number = "number",
  Numeric = "numeric",
  TextArea = "textArea",
  MultiImg = "multi-img",
  SingleImg = "single-img",
  Photo = "phone",
  Date = "date",
  Time = "time",
  Location = "location",
  Info = "info",
  Signature = "signature",
  Picker = "picker",
}
