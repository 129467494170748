import { Image } from "react-konva";
import Konva from "konva";
import React from "react";
import useImage from "use-image";

const CustomImageComponent = ({ x, y, src }) => {
    const [image] = useImage(src);
  
    return <Image x={x} y={y} image={image} />;
  };

  export default CustomImageComponent;