import React, { FC } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { FormField, FormFieldType } from "../interfaces";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Button from "react-bootstrap/Button";

type Props = {
  setFields(fields: any): void;
  fields: FormField[];
  key: any;
  title: string;
};

const FieldsComponent: FC<Props> = ({ fields, setFields, key, title }) => (
  <>
    {fields ? (
      <>
        <Col md={12}>
          <p style={{ fontWeight: "bold" }}>Editing "{title}" Fields</p>
        </Col>

        <Formik
          initialValues={{ fields: fields }}
          onSubmit={(values) => setFields(values.fields)}
          render={({ values }) => (
            <Form>
              <FieldArray
                name="fields"
                render={(arrayHelpers) => (
                  <>
                    {values.fields && values.fields.length > 0 ? (
                      values.fields.map((page, index) => (
                        <Col md={4}>
                          <Card key={index} style={{ marginBottom: 15 }}>
                            <Card.Header>
                              {/* <label>
                              Title: */}
                              <Field
                                name={`fields.${index}.title`}
                                className="form-control"
                              />
                              {/* </label> */}
                            </Card.Header>
                            <Card.Body>
                              <label>
                                Type:
                                <Field
                                  component="select"
                                  name={`fields.${index}.type`}
                                  className="form-control"
                                >
                                  {/* <option value="" />
                                  <option value="text">Text</option>
                                  <option value="number">Number</option>
                                  <option value="phone">Phone</option> */}

                                  {Object.keys(FormFieldType).map( key =>
                                    <option value={FormFieldType[key]}>{key}</option>
                                  )}
                                </Field>
                              </label>

                              <label>
                                Name:
                                <Field
                                  name={`fields.${index}.name`}
                                  className="form-control"
                                />
                              </label>

                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                variant={"danger"}
                              >
                                -
                              </Button>
                              <Button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.insert(index, {
                                    title: "",
                                    type: "",
                                    name: "",
                                  })
                                }
                                variant={"success"}
                              >
                                +
                              </Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    ) : (
                      <button
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({ title: "", type: "", name: "" })
                        }
                      >
                        {/* show this when user has removed all friends from the list */}
                        Add a page
                      </button>
                    )}
                    <div>
                      <Button className="submit-button" type="submit">
                        Apply Changes
                      </Button>
                    </div>
                  </>
                )}
              />
            </Form>
          )}
        />
      </>
    ) : (
      <Col>
      <Card style={{height: "100%"}}>
        <Card.Body style={{display: "flex", justifyContent: "center", alignItems: "center"}}>


        <p style={{fontWeight: "bold"}}>Please select a form to edit.</p>

        </Card.Body>
      </Card>      </Col>
    )}
  </>
);

export default FieldsComponent;
