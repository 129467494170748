import React, { FC } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { Page } from "../interfaces";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

type Props = {
  setPages(pages: Page[]): void;
  pages: Page[];
  setActiveForms(index: number): void;
};

const PagesComponent: FC<Props> = ({ pages, setPages, setActiveForms }) => (
  <>
    <p style={{ fontWeight: "bold" }}>Pages</p>
    <Formik
      initialValues={{ pages: pages }}
      onSubmit={(values) => {
        console.log(pages);
        console.log(values);
        setPages(values.pages);
      }}
      render={({ values }) => (
        <Form>
          <FieldArray
            name="pages"
            render={(arrayHelpers) => (
              <div>
                {values.pages && values.pages.length > 0 ? (
                  values.pages.map((page, index) => (
                    <Card key={index} style={{ marginBottom: 15 }}>
                      <Card.Header>
                        <Field
                          className="form-control"
                          name={`pages[${index}].title`}
                        />
                      </Card.Header>

                      <Card.Body>
                        <Button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          variant={"danger"}
                        >
                          -
                        </Button>
                        <Button
                          type="button"
                          onClick={() =>
                            arrayHelpers.insert(index, { title: "", forms: [] })
                          }
                          variant={"success"}
                        >
                          +
                        </Button>
                        <Button
                          type="button"
                          onClick={() => setActiveForms(index)}
                        >
                          Edit Forms
                        </Button>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <Button
                    type="button"
                    onClick={() => arrayHelpers.push({ title: "", forms: [] })}
                  >
                    Add a page
                  </Button>
                )}
                <div>
                  <Button className="submit-button" type="submit">
                    Apply Changes{" "}
                  </Button>
                </div>
              </div>
            )}
          />
        </Form>
      )}
    />
  </>
);

export default PagesComponent;
