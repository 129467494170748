import React, { useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Accordian from "react-bootstrap/Accordion";

import Zip from "jszip";

const FileReaderComponent = props => {
  const [state, setState] = useState({ pages: [] });

  const fileInput = useRef(null);

  const handleFileInput = event => {
    event.preventDefault();
    const f = fileInput.current.files[0];
    const reader: FileReader = new FileReader();

    reader.onload = (e: Event) => {
      const file = reader.result as string;

      Zip.loadAsync(file).then(async unzippedFiles => {
        const uzfs = unzippedFiles.files;

        const csPages = [];

        for (let uzf in uzfs) {
          const csPageObj = {};
          const csPageLines = [];

          csPageObj["page"] = uzf;
          csPageObj["lines"] = csPageLines;
          csPageObj["sections"] = [];

          await uzfs[uzf].async("text").then(function(u8) {
            if (uzf.search(".txt") > -1) {
              const lines = u8.split(/\r\n|\n/);
              const sectionStartIndices = [];

              lines.forEach((line, index) => {
                if (line.includes("Section:")) {
                  sectionStartIndices.push(index);
                }
              });

              const sectionObjects = [];

              sectionStartIndices.forEach((number, index) => {
                const sectionObject = {};
                const fieldsAndValues = [];
                const sectionArray = lines.slice(
                  number,
                  sectionStartIndices[index + 1]
                );
                sectionObject["heading"] = sectionArray[0];
                sectionArray.forEach((line, index) => {
                  if (index > 0) {
                    fieldsAndValues.push(line);
                  }
                });
                sectionObject["fields"] = fieldsAndValues;
                sectionObjects.push(sectionObject);
              });

              sectionObjects.forEach((section, index) => {
                const newFields = [];

                const fieldStartIndices = [];

                section.fields.forEach((el, index) => {
                  if (el.includes("Field:")) {
                    fieldStartIndices.push(index);
                  }
                });

                fieldStartIndices.forEach((number, index) => {
                  const fieldObject = {};
                  const valueStrings = [];
                  const fieldArray = section.fields.slice(
                    number,
                    fieldStartIndices[index + 1]
                  );
                  fieldObject["heading"] = fieldArray[0];
                  fieldArray.forEach((line, index) => {
                    if (index > 0) {
                      valueStrings.push(line);
                    }
                  });
                  fieldObject["value"] = valueStrings[1];
                  //TODO is this good?
                  if (valueStrings[1].length) {
                    newFields.push(fieldObject);
                  }
                });

                section["fields"] = newFields;
              });

              csPageObj["sections"] = sectionObjects;
            }
          });

          csPages.push(csPageObj);
        }

        setState({ pages: csPages });
        console.log(csPages);
      });
    };
    reader.readAsArrayBuffer(f);
  };
  return (
    <Row>
      <Col md={12}>
        <p>Content Snare Helper</p>
        <Accordian>
          {state.pages.map((page, pageIndex) => (
            <>
              {/* <h2>{page.page}</h2> */}
              {page.sections.map((section, sectionIndex) => (
                <>
                  {section.fields.length ? (
                    <div className={`csPage${pageIndex}Section${sectionIndex}`}>
                      <div className={"csField"}>
                        <Card>
                          <Card.Header>
                            <Accordian.Toggle
                              as={Button}
                              variant="link"
                              eventKey={`csPage${pageIndex}Section${sectionIndex}`}
                            >
                              <h3>{section.heading}</h3>
                            </Accordian.Toggle>
                          </Card.Header>
                          <Accordian.Collapse
                            eventKey={`csPage${pageIndex}Section${sectionIndex}`}
                          >
                            <Card.Body>
                              {section.fields.map(field => (
                                <div>
                                  {field.value ? (
                                    <div>
                                      <p
                                        style={{
                                          margin: "0 15px",
                                          fontWeight: "bold"
                                        }}
                                      >
                                        {field.heading}
                                      </p>
                                      <p style={{ margin: "0 15px" }}>
                                        {field.value}
                                      </p>{" "}
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                            </Card.Body>
                          </Accordian.Collapse>
                        </Card>
                      </div>
                    </div>
                  ) : null}
                </>
              ))}
            </>
          ))}
        </Accordian>
      </Col>
      <Col md={12}>
        <input
          ref={fileInput}
          type="file"
          name="file"
          id="file"
          onChange={handleFileInput}
        />
      </Col>
    </Row>
  );
};

export default FileReaderComponent;
