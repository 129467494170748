import React, { FC } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { FormInt } from "../interfaces";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

type Props = {
  setForms(forms: any): void;
  forms: FormInt[];
  key: any;
  setActiveFields(index: number): void;
  title: string;
};

const FormsComponent: FC<Props> = ({
  forms,
  setForms,
  key,
  setActiveFields,
  title,
}) => (
  <>
    {forms ? (
      <>
        <p style={{ fontWeight: "bold" }}>Editing "{title}" Forms</p>
        <Formik
          initialValues={{ forms: forms }}
          onSubmit={(values) => setForms(values.forms)}
          render={({ values }) => (
            <Form>
              <FieldArray
                name="forms"
                render={(arrayHelpers) => (
                  <div>
                    {values.forms && values.forms.length > 0 ? (
                      values.forms.map((page, index) => (
                        <Card key={index} style={{ marginBottom: 15 }}>
                          <Card.Header>
                            <Field
                              name={`forms.${index}.title`}
                              className="form-control"
                            />
                          </Card.Header>
                          {/* enableAudioRecordings: false,
          type: FormType.Contact,
          iconSet: IconSet.FontAwesome,
          iconName: "user", */}
                          <Card.Body>
                            <label>
                              Recipient Email:
                              <Field
                                name={`forms.${index}.recipEmail`}
                                className="form-control"
                              />
                            </label>
                            <label>
                              Enable Audio Recordings?
                              <Field
                                component="select"
                                name={`forms.${index}.enableAudioRecordings`}
                                className="form-control"
                              >
                                <option value="" />
                                <option value={"true"}>Yes</option>
                                <option value={"false"}>No</option>
                              </Field>
                            </label>
                            <label>

                              Icon Set
                              <Field
                                component="select"
                                name={`forms.${index}.iconSet`}
                                className="form-control"
                              >
                                <option value="" />
                                <option value={"ionIcons"}>Ion Icons</option>
                                <option value={"materialCommunityIcons"}>Material Community Icons</option>
                                <option value={"materialIcons"}>Material Icons</option>

                                <option value={"fontAwesome"}>FontAwesome</option>

                                <option value={"feather"}>Feather</option>

                              </Field>
                            </label>
                            <label>
                              Type:
                              <Field
                                component="select"
                                name={`forms.${index}.type`}
                                className="form-control"
                              >
                                <option value="" />
                                <option value="profile">Profile</option>
                                <option value="contact">Contact</option>
                              </Field>
                            </label>
                            <label>
                              Icon Name:
                              <Field
                              name={`forms.${index}.iconName`}
                              className="form-control"
                            />
                            </label>
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              variant={"danger"}
                            >
                              -
                            </Button>
                            <Button
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({ title: "", fields: [] })
                              }
                              variant={"success"}
                            >
                              +
                            </Button>
                            <Button
                              type="button"
                              onClick={() => setActiveFields(index)}
                            >
                              Edit Fields
                            </Button>
                          </Card.Body>
                        </Card>
                      ))
                    ) : (
                      <Button
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({ title: "", fields: [] })
                        }
                      >
                        {/* show this when user has removed all friends from the list */}
                        Add a page
                      </Button>
                    )}
                    <div>
                      <Button className="submit-button" type="submit">
                        Apply Changes
                      </Button>
                    </div>
                  </div>
                )}
              />
            </Form>
          )}
        />
      </>
    ) : (
      <Card style={{ height: "100%" }}>
        <Card.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Please select a page to edit.</p>
        </Card.Body>
      </Card>
    )}
  </>
);

export default FormsComponent;
