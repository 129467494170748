// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:62b5d9e5-276f-4daa-94c1-5838fe58a80b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_UKU8VcUgE",
    "aws_user_pools_web_client_id": "5qruch8p8f2r3gch7uefag4mrs",
    "oauth": {}
};


export default awsmobile;
