import React, { useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import StockImageCaptureComponent from "./StockImageCaptureComponent";

// import {H} from "@twest1988/codecolor-rn-ui"
const StockImageSearchComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stockImages, setStockImages] = useState([]);
  const [tags, setTags] = useState([]);

  return (
    <Row>
      <Col md={12}>
        <h1>Search for Stock Images</h1>
      </Col>
      <Col md={12}>
        <AsyncTypeahead
          id={"stock-img"}
          isLoading={isLoading}
          labelKey={(option) => `${option.name}`}
          useCache={false}
          onSearch={(query) => {
            setIsLoading(true);
            fetch(
              `https://cbkq91me79.execute-api.us-east-1.amazonaws.com/main/img/${query}`
            )
              .then((resp) => resp.json())
              .then((json) => {
                console.log(json);
                setIsLoading(false);
                setStockImages(json);
                // setTags(makeTagArray(json))
              });
          }}
          options={stockImages}
          renderMenu={(props) => null}
          placeholder={"Search for image tag or filename..."}
        />
        <Row>
          {stockImages.map((img) => (
            <Col md={3} key={img.url}>
              <div style={{ padding: 5 }}>
                <img
                  style={{ width: "100%" }}
                  alt={"preview"}
                  src={img.url}
                ></img>
                {img.tag ? (
                  <Badge variant={"secondary"} style={styles.imgMeta}>
                    {img.tag}
                  </Badge>
                ) : null}

                <Badge variant={"info"} style={styles.imgMeta}>
                  {img.width}x{img.height}
                </Badge>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};
export default StockImageSearchComponent;

const styles = {
  imgMeta: {
    marginTop: 5,
    marginRight: 5,
  },
};
